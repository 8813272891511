import React from "react";
import AuthStatus from "./AuthStatus";
import { UserId } from "./store/types/user.types";

interface HeaderProps {
  isAuthenticated: boolean;
  user: UserId | null;
  onLogout: () => void;
  onSignInClick: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isAuthenticated,
  user,
  onLogout,
  onSignInClick,
}) => {
  return (
    <header className="bg-white border-b">
      <div className="max-w-6xl mx-auto p-4">
        <div className="flex justify-between items-center">
          <div className="flex-1">
            <h1 className="text-2xl font-bold">map2vector</h1>
          </div>

          <div className="flex-1 flex justify-end">
            <AuthStatus
              user={isAuthenticated ? user : null}
              onLogout={onLogout}
              onSignInClick={onSignInClick}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
