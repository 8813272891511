interface ParsedError {
  message: string;
  status: number;
  statusText: string;
  headers: Record<string, string>;
  url: string;
  raw?: unknown; // Original error response if parsing succeeds
}

export async function parseErrorResponse(
  response: Response
): Promise<ParsedError> {
  let message: string;
  let raw: unknown;

  try {
    raw = await response.json();
    message =
      typeof raw === "object" && raw !== null
        ? (raw as any).message || (raw as any).error || JSON.stringify(raw)
        : String(raw);
  } catch {
    message = await response.text().catch(() => response.statusText);
  }

  return {
    message,
    status: response.status,
    statusText: response.statusText,
    headers: Object.fromEntries(response.headers.entries()),
    url: response.url,
    raw,
  };
}
