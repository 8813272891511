import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import L from "leaflet";
import { MapContainer, TileLayer, FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import { SearchComponent } from "./SearchComponent";
import { AlertTriangle, MapIcon, Square } from "lucide-react";
import ErrorBoundary from "./providers/ErrorBoundary";

interface MapComponentProps {
  onAreaSelect: (bounds: L.LatLngBounds) => void;
  onDrawStart: () => void;
  isDrawingMode: boolean;
  disableManualDrawMode: () => void;
}

const MapWithDrawingControl = forwardRef((props: MapComponentProps, ref) => {
  const { onAreaSelect, disableManualDrawMode } = props;
  const drawnItemsRef = useRef<L.FeatureGroup | null>(null);
  // @ts-ignore
  const [drawControl, setDrawControl] = useState<Map | null>(null);

  const map = useMap();

  useEffect(() => {
    return () => {
      if (drawnItemsRef.current) {
        drawnItemsRef.current.clearLayers();
      }
    };
  }, []);

  const _onDrawCreated = (e: any) => {
    const layer = e.layer;

    if (drawnItemsRef.current) {
      drawnItemsRef.current.clearLayers();
      drawnItemsRef.current.addLayer(layer);

      if (layer && layer.getBounds) {
        const bounds = layer.getBounds();
        onAreaSelect(bounds);

        map.fitBounds(bounds, {
          padding: [50, 50],
          maxZoom: 15,
          animate: true,
        });
      }
    }

    disableManualDrawMode();
  };

  // Forwarded ref to enable drawing mode from the parent component
  useImperativeHandle(ref, () => ({
    startRectangleDrawing() {
      if (map) {
        // @ts-ignore
        const drawControl = new L.Draw.Rectangle(map, {});
        setDrawControl(drawControl);
        drawControl.enable();
      }
    },
    stopRectangleDrawing() {
      if (drawControl) {
        drawControl.disable();
        setDrawControl(null);
      }
    },
  }));

  return (
    <>
      <ErrorBoundary>
        <SearchComponent />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <FeatureGroup
          ref={(ref) => {
            drawnItemsRef.current = ref
              ? (ref as unknown as L.FeatureGroup)
              : null;
          }}
        >
          <EditControl
            position="topleft"
            onCreated={_onDrawCreated}
            draw={{
              rectangle: true,
              polygon: false,
              polyline: false,
              circle: false,
              marker: false,
              circlemarker: false,
            }}
          />
        </FeatureGroup>
      </ErrorBoundary>
    </>
  );
});

const MapComponent = forwardRef<any, MapComponentProps>(
  (
    { onAreaSelect, onDrawStart, isDrawingMode, disableManualDrawMode },
    ref
  ) => (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <MapIcon className="h-6 w-6" />
            <div>
              <h2 className="text-lg font-semibold">Select Your Area</h2>
              <p className="text-sm text-gray-600">
                Use this button to select your area
              </p>
            </div>
          </div>
          <button
            onClick={onDrawStart}
            className={`
                flex items-center gap-2 px-4 py-2 rounded-md
                transition-colors duration-200
                ${
                  isDrawingMode
                    ? "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }
              `}
          >
            <Square className="h-4 w-4" />
            <span>{isDrawingMode ? "Drawing Mode Active" : "Select area"}</span>
          </button>
        </div>
      </div>
      <div className="p-4">
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          style={{
            height: "600px",
            width: "100%",
            position: "relative",
            zIndex: 0,
          }}
        >
          <MapWithDrawingControl
            ref={ref}
            onAreaSelect={onAreaSelect}
            onDrawStart={onDrawStart}
            isDrawingMode={isDrawingMode}
            disableManualDrawMode={disableManualDrawMode}
          />
        </MapContainer>

        {isDrawingMode && (
          <div className="mt-4 bg-blue-50 border border-blue-200 rounded-md p-4">
            <div className="flex gap-2 items-center text-blue-700">
              <AlertTriangle className="h-5 w-5" />
              <p>
                Click and drag on the map to draw your rectangle. Click the
                button again to cancel.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
);

export default MapComponent;
