export function formatDistance(
  value: number,
  unit: "km" | "mi",
  decimalDigits = 2
): string {
  const power = 1;

  return convertKmMiles(value, unit, power, decimalDigits);
}

export function formatArea(
  value: number,
  unit: "km" | "mi",
  decimalDigits = 2
) {
  const power = 2;

  return convertKmMiles(value, unit, power, decimalDigits);
}

function convertKmMiles(
  value: number,
  unit: "km" | "mi",
  power: 1 | 2,
  decimalDigits: number
): string {
  const toMi = 0.621371;
  const toKm = 1;

  const conversionFactor = unit === "km" ? toKm : toMi;
  const convertedValue = value * Math.pow(conversionFactor, power);
  const outputUnit = unit === "km" ? "km" : "mi";
  const formattedUnit = power === 1 ? outputUnit : `${outputUnit}²`;

  return `${convertedValue.toFixed(decimalDigits)} ${formattedUnit}`;
}
