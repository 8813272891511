import { SchemaType } from "../../types/seo";

// Helper function to format the schema based on type
export const formatSchema = (schema: SchemaType) => {
  const baseSchema = {
    "@context": "https://schema.org",
  };

  switch (schema.type) {
    case "Organization":
      return {
        ...baseSchema,
        "@type": "Organization",
        name: schema.name,
        url: schema.url,
        ...(schema.logo && { logo: schema.logo }),
        ...(schema.sameAs && { sameAs: schema.sameAs }),
      };

    case "Article":
      return {
        ...baseSchema,
        "@type": "Article",
        headline: schema.headline,
        description: schema.description,
        author: {
          "@type": "Person",
          name: schema.author,
        },
        datePublished: schema.datePublished,
        ...(schema.image && { image: schema.image }),
      };

    case "Product":
      return {
        ...baseSchema,
        "@type": "Product",
        name: schema.name,
        description: schema.description,
        offers: {
          "@type": "Offer",
          price: schema.price,
          priceCurrency: schema.priceCurrency,
          ...(schema.availability && {
            availability: `https://schema.org/${schema.availability}`,
          }),
        },
        ...(schema.image && { image: schema.image }),
      };

    case "FAQPage":
      return {
        ...baseSchema,
        "@type": "FAQPage",
        mainEntity: schema.questions.map((q) => ({
          "@type": "Question",
          name: q.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: q.answer,
          },
        })),
      };
  }
};
