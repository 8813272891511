import { UserId } from "./types/user.types";

export interface AuthState {
  user: UserId | null;
  token: string | null;
  isAuthenticated: boolean;
}

export enum AuthActionType {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGOUT = "LOGOUT",
  TOKEN_REFRESH = "TOKEN_REFRESH",
}

interface LoginSuccessAction {
  type: AuthActionType.LOGIN_SUCCESS;
  payload: {
    user: UserId;
    token: string;
  };
}

interface TokenRefreshSuccessAction {
  type: AuthActionType.TOKEN_REFRESH;
  payload: {
    token: string;
  };
}

interface LogoutAction {
  type: AuthActionType.LOGOUT;
}

export type AuthAction =
  | LoginSuccessAction
  | LogoutAction
  | TokenRefreshSuccessAction;
