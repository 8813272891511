import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface PaymentProviderProps {
  clientSecret: string;
  children: React.ReactNode;
}

export const PaymentProvider: React.FC<PaymentProviderProps> = ({
  clientSecret,
  children,
}) => (
  <Elements
    stripe={stripePromise}
    options={{
      clientSecret,
      appearance: {
        theme: "stripe",
      },
    }}
  >
    {children}
  </Elements>
);
