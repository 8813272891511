// auth/context/AuthContext.tsx
import React, { createContext, useContext, useReducer, useEffect } from "react";
import { AuthAction, AuthActionType, AuthState } from "./types";
import { AuthService } from "../services/auth";
import { UserId } from "./types/user.types";

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionType.LOGIN_SUCCESS:
      return {
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case AuthActionType.LOGOUT:
      return initialState;
    case AuthActionType.TOKEN_REFRESH:
      return {
        ...state,
        token: action.payload.token,
      };
    default:
      return state;
  }
};

interface AuthContextValue {
  state: AuthState;
  login: (user: UserId, token: string) => void;
  logout: () => void;
  refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");

    if (accessToken && idToken) {
      try {
        const user = AuthService.extractUserFromTokens({
          accessToken,
          idToken,
          refreshToken: localStorage.getItem("refreshToken") || "",
        });

        dispatch({
          type: AuthActionType.LOGIN_SUCCESS,
          payload: { user, token: accessToken },
        });
      } catch (error) {
        AuthService.logout();
      }
    }
  }, []);

  const login = async (user: UserId, token: string) => {
    dispatch({
      type: AuthActionType.LOGIN_SUCCESS,
      payload: { user, token },
    });
  };

  const logout = () => {
    AuthService.logout();
    dispatch({ type: AuthActionType.LOGOUT });
  };

  const refreshToken = async () => {
    try {
      const tokens = await AuthService.refreshTokens();
      dispatch({
        type: AuthActionType.TOKEN_REFRESH,
        payload: { token: tokens.accessToken },
      });
    } catch (error) {
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ state, login, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
