import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "leaflet-geosearch/dist/geosearch.css";
import { AuthProvider } from "./store/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorProvider from "./providers/ErrorProvider";
import ErrorBoundary from "./providers/ErrorBoundary";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ErrorBoundary>
        <ErrorProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </QueryClientProvider>
        </ErrorProvider>
      </ErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
