import { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { PaymentFormProps, PaymentStatus } from "./types";
import { AlertCircle, CheckCircle2, Loader2, CreditCard } from "lucide-react";
import { useError } from "../../providers/ErrorProvider";

export const PaymentForm: React.FC<PaymentFormProps> = ({
  onError,
  clientSecret,
}) => {
  const { addError } = useError();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>({
    status: "idle",
  });
  const [showPaymentInterface, setShowPaymentInterface] = useState(true);

  const handlePayment = async () => {
    if (!stripe || !elements || !clientSecret) {
      addError(
        "Payment processing is not ready yet. Please try again.",
        "error"
      );
      return;
    }

    setIsLoading(true);
    setPaymentStatus({ status: "processing" });

    try {
      console.log({ elements, stripe });
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + "/payment-completion",
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentStatus({
          status: "error",
          message: error.message || "Payment failed",
        });
        onError(error.message || "Payment failed");
      } else if (paymentIntent?.status === "succeeded") {
        setPaymentStatus({
          status: "success",
          message: "Payment successful! Your file is being generated.",
        });
        setShowPaymentInterface(false);
        console.log({ paymentIntent });
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Payment processing failed";
      setPaymentStatus({
        status: "error",
        message: errorMessage,
      });
      onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const StatusAlert = () => {
    const variants = {
      processing: {
        icon: <Loader2 className="animate-spin text-blue-500" size={18} />,
        className: "bg-blue-50 border border-blue-200 text-blue-700",
      },
      error: {
        icon: <AlertCircle className="text-red-500" size={18} />,
        className: "bg-red-50 border border-red-200 text-red-700",
      },
      success: {
        icon: <CheckCircle2 className="text-green-500" size={18} />,
        className: "bg-green-50 border border-green-200 text-green-700",
      },
    };

    if (paymentStatus.status === "idle") return null;

    const variant = variants[paymentStatus.status as keyof typeof variants];

    return (
      <div
        className={`mb-4 p-4 rounded-md flex items-start gap-2 ${variant.className}`}
      >
        {variant.icon}
        <div>{paymentStatus.message}</div>
      </div>
    );
  };

  if (paymentStatus.status === "success") {
    return (
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-md">
        <div className="p-6">
          <StatusAlert />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="text-lg font-semibold mb-4 flex items-center gap-2">
          <CreditCard size={20} />
          Payment Details
        </div>

        <div className="space-y-6">
          <StatusAlert />

          <PaymentElement
            options={{
              layout: "tabs",
            }}
          />

          <button
            onClick={handlePayment}
            disabled={!stripe || !elements || isLoading}
            className={`
              w-full py-2 px-4 rounded-md flex items-center justify-center gap-2
              ${
                !stripe || !elements || isLoading
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700 text-white"
              }
            `}
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin" size={18} />
                Processing...
              </>
            ) : (
              <>
                <CreditCard size={18} />
                Pay Now
              </>
            )}
          </button>

          <div className="text-center text-sm text-gray-500">
            Powered by{" "}
            <a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700"
            >
              Stripe
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
