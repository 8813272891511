import React, { useState, useEffect } from "react";
import { Lock, Mail, User, AlertTriangle, X, ArrowLeft } from "lucide-react";
import { AuthService } from "./services/auth";
import { UserId } from "./store/types/user.types";
import {
  AuthFormData,
  AuthMode,
  ValidationErrors,
  validateForm,
  checkFormValidity,
  checkPasswordRequirements,
} from "./utils/validations/auth-validations";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthenticated: (user: UserId, token: string) => void;
}

interface RecoveryFormData extends AuthFormData {
  code?: string;
  newPassword?: string;
}

const AuthModal: React.FC<AuthModalProps> = ({
  isOpen,
  onClose,
  onAuthenticated,
}) => {
  const [mode, setMode] = useState<AuthMode>("login");
  const [formData, setFormData] = useState<RecoveryFormData>({
    email: "",
    password: "",
    name: "",
    code: "",
    newPassword: "",
  });
  const [error, setError] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [recoveryCodeSent, setRecoveryCodeSent] = useState(false);

  useEffect(() => {
    setIsFormValid(checkFormValidity(formData, mode));
  }, [formData, mode]);

  useEffect(() => {
    if (
      (mode === "signup" || (mode === "recover" && recoveryCodeSent)) &&
      (formData.password || formData.newPassword)
    ) {
      const errors = validateForm(formData, mode);
      setValidationErrors((prev) => ({
        ...prev,
        password: errors.password,
        newPassword: errors.password,
      }));
    } else {
      setValidationErrors((prev) => ({
        ...prev,
        password: undefined,
        newPassword: undefined,
      }));
    }
  }, [formData.password, formData.newPassword, mode, recoveryCodeSent]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    const errors = validateForm(formData, mode);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsLoading(true);

    try {
      if (mode === "signup") {
        const response = await AuthService.signup({
          email: formData.email,
          password: formData.password,
          name: formData.name,
        });
        onAuthenticated(response.user, response.token);
        onClose();
      } else if (mode === "login") {
        const response = await AuthService.login({
          email: formData.email,
          password: formData.password,
        });
        onAuthenticated(response.user, response.token);
        onClose();
      } else if (mode === "recover") {
        if (!recoveryCodeSent) {
          await AuthService.initiatePasswordRecovery({
            email: formData.email,
          });
          setRecoveryCodeSent(true);
        } else {
          await AuthService.confirmPasswordReset({
            email: formData.email,
            code: formData.code || "",
            newPassword: formData.newPassword || "",
          });
          setMode("login");
          setFormData({
            email: formData.email,
            password: "",
            name: "",
            code: "",
            newPassword: "",
          });
          setRecoveryCodeSent(false);
        }
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "Authentication failed");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name !== "password" && name !== "newPassword") {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }

    setError("");
  };

  const renderFieldError = (fieldName: keyof ValidationErrors) => {
    const error = validationErrors[fieldName];
    if (!error) return null;

    return (
      <p className="text-sm text-red-600 mt-1 flex items-center gap-1">
        <AlertTriangle className="h-4 w-4" />
        {error}
      </p>
    );
  };

  const renderPasswordRequirements = (password: string) => {
    if ((mode !== "signup" && !recoveryCodeSent) || !password) return null;

    const requirements = checkPasswordRequirements(password);

    return (
      <div className="mt-2 space-y-1">
        {requirements.map((req, index) => (
          <p
            key={index}
            className={`text-sm flex items-center gap-1 ${
              req.met ? "text-green-600" : "text-gray-500"
            }`}
          >
            {req.met ? "✓" : "○"} {req.text}
          </p>
        ))}
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={onClose}
      />

      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md">
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="hover:bg-gray-100 p-1 rounded-md transition-colors"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          {mode === "recover" && (
            <button
              onClick={() => {
                if (recoveryCodeSent) {
                  setRecoveryCodeSent(false);
                } else {
                  setMode("login");
                }
                setFormData({
                  email: "",
                  password: "",
                  name: "",
                  code: "",
                  newPassword: "",
                });
              }}
              className="absolute left-4 top-4 hover:bg-gray-100 p-1 rounded-md transition-colors"
            >
              <ArrowLeft className="h-5 w-5 text-gray-500" />
            </button>
          )}

          <div className="p-4 border-b border-gray-200">
            <div>
              <h2 className="text-lg font-semibold">
                {mode === "login"
                  ? "Sign in to continue"
                  : mode === "signup"
                  ? "Create your account"
                  : recoveryCodeSent
                  ? "Reset your password"
                  : "Recover your password"}
              </h2>
              <p className="text-sm text-gray-600">
                {mode === "login"
                  ? "Sign in to download your selected area"
                  : mode === "signup"
                  ? "Create an account to download map data"
                  : recoveryCodeSent
                  ? "Enter the code sent to your email"
                  : "Enter your email to reset your password"}
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="p-4 space-y-4">
            {error && (
              <div className="bg-red-50 border border-red-200 rounded-md p-3">
                <div className="flex gap-2 items-center text-red-700">
                  <AlertTriangle className="h-5 w-5" />
                  <p>{error}</p>
                </div>
              </div>
            )}

            {mode === "recover" ? (
              !recoveryCodeSent ? (
                <div className="space-y-1">
                  <label className="text-sm font-medium" htmlFor="email">
                    Email
                  </label>
                  <div className="relative">
                    <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className={`w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                        validationErrors.email
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                      placeholder="you@example.com"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {renderFieldError("email")}
                </div>
              ) : (
                <>
                  <div className="space-y-1">
                    <label className="text-sm font-medium" htmlFor="code">
                      Recovery Code
                    </label>
                    <input
                      id="code"
                      name="code"
                      type="text"
                      className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent border-gray-200"
                      placeholder="Enter code from email"
                      value={formData.code}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="space-y-1">
                    <label
                      className="text-sm font-medium"
                      htmlFor="newPassword"
                    >
                      New Password
                    </label>
                    <div className="relative">
                      <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                      <input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        className={`w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                          validationErrors.newPassword
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                        placeholder="••••••••"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    {renderFieldError("newPassword")}
                    {renderPasswordRequirements(formData.newPassword || "")}
                  </div>
                </>
              )
            ) : (
              <>
                {mode === "signup" && (
                  <div className="space-y-1">
                    <label className="text-sm font-medium" htmlFor="name">
                      Full Name
                    </label>
                    <div className="relative">
                      <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className={`w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                          validationErrors.name
                            ? "border-red-500"
                            : "border-gray-200"
                        }`}
                        placeholder="John Doe"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    {renderFieldError("name")}
                  </div>
                )}

                <div className="space-y-1">
                  <label className="text-sm font-medium" htmlFor="email">
                    Email
                  </label>
                  <div className="relative">
                    <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className={`w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                        validationErrors.email
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                      placeholder="you@example.com"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {renderFieldError("email")}
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium" htmlFor="password">
                    Password
                  </label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className={`w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                        validationErrors.password
                          ? "border-red-500"
                          : "border-gray-200"
                      }`}
                      placeholder="••••••••"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {renderFieldError("password")}
                  {mode === "signup" &&
                    renderPasswordRequirements(formData.password)}
                </div>
              </>
            )}

            <button
              type="submit"
              className={`w-full py-2 px-4 rounded-md transition-colors duration-200 ${
                isFormValid
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!isFormValid || isLoading}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </span>
              ) : mode === "login" ? (
                "Sign In"
              ) : mode === "signup" ? (
                "Create Account"
              ) : recoveryCodeSent ? (
                "Reset Password"
              ) : (
                "Send Reset Link"
              )}
            </button>

            <div className="pt-4 border-t border-gray-200">
              {mode === "login" ? (
                <>
                  <p className="text-sm text-center">
                    New to map2vector?{" "}
                    <button
                      type="button"
                      onClick={() => {
                        setMode("signup");
                        setFormData({
                          email: "",
                          password: "",
                          name: "",
                          code: "",
                          newPassword: "",
                        });
                        setError("");
                      }}
                      className="text-blue-600 hover:underline"
                    >
                      Create an account
                    </button>
                  </p>
                  <p className="text-sm text-center mt-4">
                    <button
                      type="button"
                      onClick={() => {
                        setMode("recover");
                        setFormData({
                          email: "",
                          password: "",
                          name: "",
                          code: "",
                          newPassword: "",
                        });
                        setError("");
                      }}
                      className="text-gray-600 hover:underline"
                    >
                      Forgot your password?
                    </button>
                  </p>
                </>
              ) : (
                <p className="text-sm text-center">
                  Already have an account?{" "}
                  <button
                    type="button"
                    onClick={() => {
                      setMode("login");
                      setFormData({
                        email: "",
                        password: "",
                        name: "",
                        code: "",
                        newPassword: "",
                      });
                      setError("");
                    }}
                    className="text-blue-600 hover:underline"
                  >
                    Sign in
                  </button>
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
