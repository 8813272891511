import { AuthService } from "../services/auth";
import { parseErrorResponse } from "../utils/handle-fetch-error";

interface ApiServiceConfig {
  baseURL: string;
}

interface RequestConfig extends RequestInit {
  requiresAuth?: boolean;
}

export class ApiService {
  private baseURL: string;

  constructor(config: ApiServiceConfig) {
    this.baseURL = config.baseURL;
  }

  private async getHeaders(requiresAuth: boolean = true): Promise<Headers> {
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    if (requiresAuth) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        headers.append("Authorization", `Bearer ${accessToken}`);
      }
    }

    return headers;
  }

  private async fetch<T>(
    endpoint: string,
    config: RequestConfig = {}
  ): Promise<T> {
    const { requiresAuth = true, ...requestConfig } = config;
    const url = `${this.baseURL}${endpoint}`;

    const makeRequest = async (): Promise<T> => {
      const headers = await this.getHeaders(requiresAuth);
      const response = await fetch(url, {
        ...requestConfig,
        headers: {
          ...Object.fromEntries(headers.entries()),
          ...requestConfig.headers,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error("401");
        }

        const errorResponse = await parseErrorResponse(response);
        throw new Error(errorResponse.message);
      }

      return response.json();
    };

    return AuthService.executeWithRefresh(makeRequest);
  }

  async get<T>(endpoint: string, config?: RequestConfig): Promise<T> {
    return this.fetch<T>(endpoint, {
      ...config,
      method: "GET",
    });
  }

  async post<T>(
    endpoint: string,
    data?: any,
    config?: RequestConfig
  ): Promise<T> {
    return this.fetch<T>(endpoint, {
      ...config,
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async put<T>(
    endpoint: string,
    data?: any,
    config?: RequestConfig
  ): Promise<T> {
    return this.fetch<T>(endpoint, {
      ...config,
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async delete<T>(endpoint: string, config?: RequestConfig): Promise<T> {
    return this.fetch<T>(endpoint, {
      ...config,
      method: "DELETE",
    });
  }
}

export const api = new ApiService({
  baseURL: process.env.REACT_APP_CORE_BASE_URL || "",
});
