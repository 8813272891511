export interface PriceTier {
  minArea: number;
  maxArea: number;
  price: number;
}

export const AREA_LIMITS = {
  MAX_AREA: 50, // km²
};

export const PRICE_TIERS: PriceTier[] = [
  { minArea: 0, maxArea: 5, price: 1.99 },
  { minArea: 5, maxArea: 10, price: 2.99 },
  { minArea: 10, maxArea: 20, price: 4.99 },
  { minArea: 20, maxArea: 30, price: 7.99 },
  { minArea: 30, maxArea: 40, price: 9.99 },
  { minArea: 40, maxArea: 50, price: 16.99 },
  { minArea: 50, maxArea: 100, price: 29.99 },
];
