import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

import { useEffect } from "react";
import { useMap } from "react-leaflet";

const provider = new OpenStreetMapProvider();

export const SearchComponent = () => {
  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    style: "bar",
  });

  const map = useMap();

  // @ts-ignore
  useEffect(() => {
    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
};
