import React, { createContext, useContext, useState, useEffect } from "react";
import { AlertCircle, XCircle, Info, CheckCircle } from "lucide-react";

// Define types for our error management system
type ErrorSeverity = "error" | "warning" | "info" | "success";

interface ErrorMessage {
  id: string;
  message: string;
  severity: ErrorSeverity;
  timestamp: Date;
  isExiting?: boolean;
}

interface ErrorContextType {
  errors: ErrorMessage[];
  addError: (message: string, severity: ErrorSeverity) => void;
  removeError: (id: string) => void;
  clearErrors: () => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [errors, setErrors] = useState<ErrorMessage[]>([]);

  const addError = (message: string, severity: ErrorSeverity) => {
    const newError: ErrorMessage = {
      id: Date.now().toString(),
      message,
      severity,
      timestamp: new Date(),
    };
    setErrors((prev) => [...prev, newError]);

    setTimeout(() => {
      removeError(newError.id);
    }, 5000);
  };

  const removeError = (id: string) => {
    setErrors((prev) =>
      prev.map((error) =>
        error.id === id ? { ...error, isExiting: true } : error
      )
    );

    // Remove after animation completes
    setTimeout(() => {
      setErrors((prev) => prev.filter((error) => error.id !== id));
    }, 300);
  };

  const clearErrors = () => {
    setErrors([]);
  };

  return (
    <ErrorContext.Provider
      value={{ errors, addError, removeError, clearErrors }}
    >
      {children}
      <ErrorDisplay />
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useError must be used within an ErrorProvider");
  }
  return context;
};

const ErrorAlert = ({
  error,
  onClose,
}: {
  error: ErrorMessage;
  onClose: () => void;
}) => {
  const getIcon = (severity: ErrorSeverity) => {
    switch (severity) {
      case "error":
        return <XCircle className="h-5 w-5 text-red-500" />;
      case "warning":
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
      case "info":
        return <Info className="h-5 w-5 text-blue-500" />;
      case "success":
        return <CheckCircle className="h-5 w-5 text-green-500" />;
    }
  };

  const getBgColor = (severity: ErrorSeverity) => {
    switch (severity) {
      case "error":
        return "bg-red-50";
      case "warning":
        return "bg-yellow-50";
      case "info":
        return "bg-blue-50";
      case "success":
        return "bg-green-50";
    }
  };

  const getBorderColor = (severity: ErrorSeverity) => {
    switch (severity) {
      case "error":
        return "border-red-200";
      case "warning":
        return "border-yellow-200";
      case "info":
        return "border-blue-200";
      case "success":
        return "border-green-200";
    }
  };

  return (
    <div
      className={`
        relative flex items-start gap-3 p-4 rounded-lg border 
        ${getBgColor(error.severity)} 
        ${getBorderColor(error.severity)}
        shadow-lg
        transform transition-all duration-300 ease-in-out
        ${
          error.isExiting
            ? "opacity-0 translate-x-full"
            : "opacity-100 translate-x-0"
        }
      `}
    >
      <div className="flex-shrink-0">{getIcon(error.severity)}</div>
      <div className="flex-1 min-w-0">
        <p className="font-medium capitalize mb-1">{error.severity}</p>
        <p className="text-sm text-gray-600">{error.message}</p>
      </div>
      <button
        onClick={onClose}
        className="flex-shrink-0 text-gray-400 hover:text-gray-600 transition-colors"
      >
        <XCircle className="h-5 w-5" />
      </button>
    </div>
  );
};

const ErrorDisplay = () => {
  const { errors, removeError } = useError();

  return (
    <div className="fixed bottom-4 right-4 space-y-2 max-w-md z-50">
      {errors.map((error) => (
        <div
          key={error.id}
          className={`
            transform transition-all duration-300 ease-out
            ${
              error.isExiting
                ? "opacity-0 translate-x-full"
                : "opacity-100 translate-x-0 animate-[slideIn_0.3s_ease-out]"
            }
          `}
        >
          <ErrorAlert error={error} onClose={() => removeError(error.id)} />
        </div>
      ))}
    </div>
  );
};

export default ErrorProvider;
