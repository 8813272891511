export interface ValidationErrors {
  email?: string;
  password?: string;
  name?: string;
  newPassword?: string;
}

export interface AuthFormData {
  email: string;
  password: string;
  name: string;
  code?: string;
  newPassword?: string;
}

export type AuthMode = "login" | "signup" | "recover";

export const validateEmail = (email: string): string | undefined => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return "Email is required";
  }
  if (!emailRegex.test(email)) {
    return "Please enter a valid email address";
  }
  return undefined;
};

export const validatePassword = (
  password: string,
  mode: AuthMode
): string | undefined => {
  if (mode !== "signup") return undefined;

  if (!password) {
    return "Password is required";
  }
  if (password.length < 8) {
    return "Password must be at least 8 characters long";
  }
  if (!/\d/.test(password)) {
    return "Password must contain at least 1 number";
  }
  if (!/[a-z]/.test(password)) {
    return "Password must contain at least 1 lowercase letter";
  }
  return undefined;
};

export const validateName = (
  name: string,
  mode: AuthMode
): string | undefined => {
  if (mode === "signup" && !name.trim()) {
    return "Name is required";
  }
  return undefined;
};

export const checkPasswordRequirements = (password: string) => {
  return [
    {
      met: password.length >= 8,
      text: "At least 8 characters",
    },
    {
      met: /\d/.test(password),
      text: "Contains at least 1 number",
    },
    {
      met: /[a-z]/.test(password),
      text: "Contains at least 1 lowercase letter",
    },
  ];
};

export const validateForm = (
  formData: AuthFormData,
  mode: AuthMode
): ValidationErrors => {
  const errors: ValidationErrors = {};

  errors.email = validateEmail(formData.email);
  if (mode === "signup") {
    errors.password = validatePassword(formData.password, mode);
    errors.name = validateName(formData.name, mode);
  }

  // Remove undefined errors
  Object.keys(errors).forEach((key) => {
    if (errors[key as keyof ValidationErrors] === undefined) {
      delete errors[key as keyof ValidationErrors];
    }
  });

  return errors;
};

export const checkFormValidity = (
  formData: AuthFormData,
  mode: AuthMode
): boolean => {
  if (mode === "recover") {
    return !validateEmail(formData.email);
  }

  if (mode === "login") {
    return !validateEmail(formData.email) && formData.password.length > 0;
  }

  // Signup mode
  return (
    !validateEmail(formData.email) &&
    !validatePassword(formData.password, mode) &&
    !validateName(formData.name, mode)
  );
};
