import React, { useState, useRef, useEffect } from "react";
import MapComponent from "./MapComponent";
import { MapCoordinates } from "./types/geo";
import AuthModal from "./AuthModal";
import { useAuth } from "./store/AuthContext";
import Header from "./Header";
import SEO from "./components/seo/SEO";
import { motion } from "framer-motion";
import StructuredData from "./components/seo/StructuredData";
import { ExportPanel } from "./ExportPanel";
import { AuthService } from "./services/auth";

const App: React.FC = () => {
  const [selectedArea, setSelectedArea] = useState<MapCoordinates | null>(null);
  const [drawingMode, setDrawingMode] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const {
    state: { isAuthenticated, user },
    login,
    logout,
  } = useAuth();

  useEffect(() => {
    const cleanup = AuthService.onSessionExpired(() => {
      setIsAuthModalOpen(true);
    });

    return () => cleanup();
  }, []);

  const mapComponentRef = useRef<{
    startRectangleDrawing: () => void;
    stopRectangleDrawing: () => void;
  }>(null);

  const handleAreaSelect = (bounds: L.LatLngBounds) => {
    setSelectedArea({ bounds });
  };

  const handleStartDrawing = () => {
    if (mapComponentRef.current) {
      if (drawingMode) {
        setDrawingMode(false);
        mapComponentRef.current.stopRectangleDrawing();
      } else {
        setDrawingMode(true);
        mapComponentRef.current.startRectangleDrawing();
      }
    }
  };

  const handlePurchaseAttempt = ({ onSuccess }: { onSuccess: () => void }) => {
    if (!isAuthenticated) {
      setIsAuthModalOpen(true);
    } else {
      onSuccess();
    }
  };

  const handleExportAttempt = ({ onSuccess }: { onSuccess: () => void }) => {
    if (!isAuthenticated) {
      setIsAuthModalOpen(true);
    } else {
      onSuccess();
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <SEO
        title="Map to Vector Converter | Create Reference DXF & SVG from Map Selections"
        description="Convert map areas to DXF and SVG vector files for preliminary design work. Helpful tool for architects, engineers, and designers seeking initial geographic reference data."
        keywords={[
          "map to vector converter",
          "preliminary map vectorization",
          "reference vector conversion",
          "map to DXF converter",
          "map to SVG converter",
          "geographic reference data",
        ]}
      />
      <StructuredData />

      <Header
        isAuthenticated={isAuthenticated}
        user={user}
        onLogout={logout}
        onSignInClick={() => setIsAuthModalOpen(true)}
      />

      <div className="max-w-6xl mx-auto p-6">
        <div className="grid gap-6 md:grid-cols-2">
          <div className="space-y-4">
            <MapComponent
              ref={mapComponentRef}
              onAreaSelect={handleAreaSelect}
              onDrawStart={handleStartDrawing}
              isDrawingMode={drawingMode}
              disableManualDrawMode={() => setDrawingMode(false)}
            />
          </div>
          <div className="space-y-6">
            {selectedArea && (
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.5 }}
                className="space-y-6"
              >
                <ExportPanel
                  selectedArea={selectedArea}
                  onPurchase={handlePurchaseAttempt}
                  onExport={handleExportAttempt}
                />
              </motion.div>
            )}
          </div>
        </div>

        {/* Use Cases Section */}
        <section className="w-full bg-white py-8 shadow-sm mt-12">
          <div className="max-w-6xl mx-auto px-6">
            <h2 className="text-2xl font-semibold mb-4">
              Helpful for Initial Design Planning
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <div>
                <h3 className="font-semibold">Architecture & Construction</h3>
                <ul className="text-sm text-gray-600">
                  <li>Preliminary site studies</li>
                  <li>Reference footprints</li>
                  <li>Initial layout planning</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold">Urban Planning</h3>
                <ul className="text-sm text-gray-600">
                  <li>Conceptual zoning</li>
                  <li>Early-stage analysis</li>
                  <li>Preliminary planning</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold">Landscape Design</h3>
                <ul className="text-sm text-gray-600">
                  <li>Initial site review</li>
                  <li>Basic terrain reference</li>
                  <li>Concept development</li>
                </ul>
              </div>
              <div>
                <h3 className="font-semibold">Civil Engineering</h3>
                <ul className="text-sm text-gray-600">
                  <li>Preliminary planning</li>
                  <li>Initial site assessment</li>
                  <li>Conceptual development</li>
                </ul>
              </div>
            </div>
            <p className="text-sm text-gray-500 mt-4">
              Note: Generated vectors are intended for preliminary reference and
              planning purposes only. For precise measurements and final
              designs, please consult official surveys and verified site data.
            </p>
          </div>
        </section>
      </div>

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        onAuthenticated={(user, token) => {
          login(user, token);
          setIsAuthModalOpen(false);
        }}
      />

      {/* Footer */}
      <footer className="bg-gray-100 py-8">
        <div className="max-w-6xl mx-auto px-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-semibold mb-2">Contact</h3>
              <ul className="text-sm text-gray-600">
                <li>dpsoftworks@gmail.com</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
