export const calculateAreaMetrics = (bounds: L.LatLngBounds) => {
  const north = bounds.getNorth();
  const south = bounds.getSouth();
  const east = bounds.getEast();
  const west = bounds.getWest();

  const widthDeg = Math.abs(east - west);
  const heightDeg = Math.abs(north - south);

  // Convert to kilometers (approximate)
  // 1 degree of latitude = ~111 km
  // 1 degree of longitude = ~111 km * cos(latitude)
  const centerLat = (north + south) / 2;
  const widthKm = widthDeg * 111 * Math.cos((centerLat * Math.PI) / 180);
  const heightKm = heightDeg * 111;

  const areaKm2 = widthKm * heightKm;
  const areaMi2 = areaKm2 * 0.386102;

  return {
    dimensions: {
      width: widthKm,
      height: heightKm,
    },
    area: {
      km2: areaKm2,
      mi2: areaMi2,
    },
    bounds: {
      north,
      south,
      east,
      west,
    },
  };
};
