import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userApi } from "../api/userApi";
import { useAuth } from "../store/AuthContext";

export const useUserProfile = () => {
  const { state } = useAuth();
  const queryClient = useQueryClient();

  const {
    data: profile,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["user-profile"],
    queryFn: userApi.getUserProfile,
    enabled: state.isAuthenticated,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
  });

  const refetchProfile = async () => {
    await queryClient.invalidateQueries({ queryKey: ["user-profile"] });
  };

  return {
    profile,
    isLoading,
    error,
    refetch: refetchProfile,
  };
};
