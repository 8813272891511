import { Helmet } from "react-helmet-async";
import { SchemaType } from "../../types/seo";
import { formatSchema } from "../../utils/seo/seo.utils";

const StructuredData = () => {
  const schema: SchemaType = {
    type: "Product",
    name: "Vector file converter",
    description: "Converts map areas to vector files, such as DXF, SVG, etc.",
    price: 1.99,
    priceCurrency: "USD",
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(formatSchema(schema))}
      </script>
    </Helmet>
  );
};

export default StructuredData;
