import { Unit } from "../types/unit";

interface UnitSelectorProps {
  selectedUnit: Unit;
  onUnitChange: (unit: Unit) => void;
}

export const UnitSelector: React.FC<UnitSelectorProps> = ({
  selectedUnit,
  onUnitChange,
}) => (
  <div className="flex gap-2">
    <button
      onClick={() => onUnitChange(Unit.KM)}
      className={`px-2 py-1 text-sm rounded flex items-center gap-1 ${
        selectedUnit === Unit.KM
          ? "bg-blue-100 text-blue-700"
          : "text-gray-600 hover:bg-gray-100"
      }`}
    >
      Metric
    </button>
    <button
      onClick={() => onUnitChange(Unit.MI)}
      className={`px-2 py-1 text-sm rounded flex items-center gap-1 ${
        selectedUnit === Unit.MI
          ? "bg-blue-100 text-blue-700"
          : "text-gray-600 hover:bg-gray-100"
      }`}
    >
      Imperial
    </button>
  </div>
);
