import React, { useState, useRef, useEffect } from "react";
import { User, LogOut, Settings, ChevronDown } from "lucide-react";

interface UserType {
  name?: string;
  email?: string;
}

interface AuthStatusProps {
  user: UserType | null;
  onLogout: () => void;
  onSignInClick: () => void;
}

const AuthStatus: React.FC<AuthStatusProps> = ({
  user,
  onLogout,
  onSignInClick,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (!user) {
    return (
      <button
        type="button"
        onClick={onSignInClick}
        className="flex items-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <User size={16} />
        <span>Sign In</span>
      </button>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm">
          {user.name ? user.name[0].toUpperCase() : <User size={14} />}
        </div>
        <span className="hidden md:inline">{user.name || "User"}</span>
        <ChevronDown
          size={16}
          className={isOpen ? "rotate-180 transform" : ""}
        />
      </button>

      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
            {user.email || "user@example.com"}
          </div>

          <button
            type="button"
            className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            role="menuitem"
          >
            <Settings size={16} />
            <span>Profile Settings</span>
          </button>

          <button
            type="button"
            onClick={() => {
              onLogout();
              setIsOpen(false);
            }}
            className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            role="menuitem"
          >
            <LogOut size={16} />
            <span>Sign Out</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default AuthStatus;
