import { UserId } from "../store/types/user.types";

interface AuthTokens {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

interface SignUpRequest {
  email: string;
  password: string;
  name: string;
}

interface LoginRequest {
  email: string;
  password: string;
}

interface AuthResponse {
  user: UserId;
  token: string;
}

type SessionExpiredCallback = () => void;

const API_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || "";

export class AuthService {
  private static refreshPromise: Promise<AuthTokens> | null = null;
  private static sessionExpiredHandlers: SessionExpiredCallback[] = [];

  static onSessionExpired(callback: SessionExpiredCallback): () => void {
    this.sessionExpiredHandlers.push(callback);
    return () => {
      this.sessionExpiredHandlers = this.sessionExpiredHandlers.filter(
        (handler) => handler !== callback
      );
    };
  }

  private static handleSessionExpired() {
    this.logout();
    this.sessionExpiredHandlers.forEach((handler) => handler());
  }

  private static async handleResponse(response: Response) {
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Authentication failed");
    }

    return data;
  }

  static extractUserFromTokens(tokens: AuthTokens): UserId {
    const payload = JSON.parse(atob(tokens.idToken.split(".")[1]));

    return {
      id: payload.sub,
      email: payload.email,
      name:
        payload.name ||
        `${payload.given_name || ""} ${payload.family_name || ""}`.trim(),
    };
  }

  static async refreshTokens(): Promise<AuthTokens> {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token found");
    }

    this.refreshPromise = (async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken,
          }),
        });

        const { tokens } = await this.handleResponse(response);

        localStorage.setItem("accessToken", tokens.accessToken);
        localStorage.setItem("idToken", tokens.idToken);
        localStorage.setItem("refreshToken", tokens.refreshToken);

        return tokens;
      } finally {
        this.refreshPromise = null;
      }
    })();

    return this.refreshPromise;
  }

  static async executeWithRefresh<T>(apiCall: () => Promise<T>): Promise<T> {
    try {
      return await apiCall();
    } catch (error) {
      if (
        error instanceof Error &&
        (error.message.includes("401") ||
          error.message.includes("403") ||
          error.message.includes("Unauthorized") ||
          error.message.includes("Forbidden"))
      ) {
        try {
          await this.refreshTokens();
          return await apiCall();
        } catch (refreshError) {
          this.handleSessionExpired();
          this.logout();
          throw new Error("Session expired. Please login again.");
        }
      }
      throw error;
    }
  }

  static async signup(data: SignUpRequest): Promise<AuthResponse> {
    const nameParts = data.name.split(" ");
    const given_name = nameParts[0];
    const family_name = nameParts.slice(1).join(" ");

    return this.executeWithRefresh(async () => {
      const response = await fetch(`${API_BASE_URL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
          given_name,
          family_name,
        }),
      });

      await this.handleResponse(response);

      return await this.login({
        email: data.email,
        password: data.password,
      });
    });
  }

  static async login(data: LoginRequest): Promise<AuthResponse> {
    return this.executeWithRefresh(async () => {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const { tokens } = await this.handleResponse(response);

      const user = this.extractUserFromTokens(tokens);

      localStorage.setItem("accessToken", tokens.accessToken);
      localStorage.setItem("idToken", tokens.idToken);
      localStorage.setItem("refreshToken", tokens.refreshToken);

      return {
        user,
        token: tokens.accessToken,
      };
    });
  }

  static async initiatePasswordRecovery(data: {
    email: string;
  }): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/auth/recovery/initiate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to initiate password recovery");
    }
  }

  static async confirmPasswordReset(data: {
    email: string;
    code: string;
    newPassword: string;
  }): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/auth/recovery/confirm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to reset password");
    }
  }

  static logout(): void {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
  }

  static isAuthenticated(): boolean {
    return !!localStorage.getItem("accessToken");
  }
}
