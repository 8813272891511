import { TRIAL_ATTEMPT_MAX_AREA_KM } from "../constants";
import { UserProfile } from "../store/types/user.types";
import { AREA_LIMITS, PRICE_TIERS, PriceTier } from "../types";

const calculatePriceSimple = (
  areaKm2: number | undefined
): { price: number; tier: PriceTier | null } => {
  if (!areaKm2) return { price: 0, tier: null };

  if (areaKm2 > AREA_LIMITS.MAX_AREA) {
    return { price: 0, tier: null };
  }

  const tier = PRICE_TIERS.find(
    (tier) => areaKm2 >= tier.minArea && areaKm2 < tier.maxArea
  );

  return {
    price: tier?.price ?? 0,
    tier: tier ?? null,
  };
};

export const calculatePrice = (
  userProfile: UserProfile | undefined,
  area: number | undefined
): { price: number; isEligibleForFreeTrial: boolean } => {
  if (!area) return { price: 0, isEligibleForFreeTrial: false };

  if (userProfile) {
    if (
      userProfile.remainingTrialAttempts &&
      area <= TRIAL_ATTEMPT_MAX_AREA_KM
    ) {
      return { price: 0, isEligibleForFreeTrial: true };
    }
  }

  return {
    price: calculatePriceSimple(area).price,
    isEligibleForFreeTrial: false,
  };
};
