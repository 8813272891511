import { api } from "../services/api.service";
import {
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
} from "../store/types/payment-intent-type";

export const paymentIntentApi = {
  createPaymentIntent: (body: CreatePaymentIntentRequest) =>
    api.post<CreatePaymentIntentResponse>("/payment-intent", body),
};
