import { Gift } from "lucide-react";
import { formatArea } from "./utils/distanceAreaFormatting";
import { UserProfile } from "./store/types/user.types";
import { TRIAL_ATTEMPT_MAX_AREA_KM } from "./constants";

const DownloadStatus = ({
  isAuthenticated,
  profile,
  selectedUnit,
  isEligibleForFreeTrial,
}: {
  isAuthenticated: boolean;
  profile: UserProfile | undefined;
  selectedUnit: "km" | "mi";
  isEligibleForFreeTrial: boolean;
}) => {
  if (!isAuthenticated) {
    return (
      <div className="text-sm text-blue-600 font-medium">
        Login to get a free download
      </div>
    );
  }

  if (profile?.remainingTrialAttempts && isEligibleForFreeTrial) {
    return (
      <div className="flex items-center gap-2 text-sm text-green-600 font-medium">
        <Gift className="h-4 w-4" />
        {profile.remainingTrialAttempts} free
        {profile.remainingTrialAttempts === 1 ? " download " : " downloads "}
        (for areas under{" "}
        {formatArea(TRIAL_ATTEMPT_MAX_AREA_KM, selectedUnit, 0)})
      </div>
    );
  }

  return null;
};

export default DownloadStatus;
